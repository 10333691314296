import React from "react";
//import {useNavigate} from '@reach/router'
import withFullBoard from "./HOC/withFullBoard";
import View from "../../layout/view/View";
import Loader from "../../layout/loader/Loader";
import CollectionItem from "./items/CollectionItem";
import Collection from "./items/Collection";

const animatedClassName = "animated fadeInRightShort";

function MenuContents({ data, baseURL, menuId }) {
  //const history = useNavigate()
  //const navigate = () => history.push(baseURL + '/menus')
  if (!data) return <Loader />;
  const { contents = [] } = data;
  const item = contents.find((p) => p.id === menuId) || { contents: [] };
  return (
    <View center className="menus">
      <div className={animatedClassName}>
        <Collection className="go-list full-list">
          {item.contents.map((menu) => (
            <CollectionItem
              titleProps={{ verticalCenter: false }}
              descriptionProps={{ verticalCenter: false }}
              className="menu-item go-list-item category-item"
              key={menu.id}
              {...menu}
            >
              {menu.contents && menu.contents && menu.contents.length > 0 && (
                <Collection className="go-list">
                  {menu.contents.map((item) => (
                    <CollectionItem
                      titleProps={{ verticalCenter: false }}
                      descriptionProps={{ verticalCenter: false }}
                      className="menu-item food"
                      key={item.id}
                      {...item}
                    />
                  ))}
                </Collection>
              )}
            </CollectionItem>
          ))}
        </Collection>
      </div>
    </View>
  );
}

export default withFullBoard(MenuContents);
