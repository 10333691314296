import React from "react";
import { CircularProgress } from "@mui/material";
import { useTheme } from "@mui/styles";

//import PropTypes from 'prop-types';

// const styles = theme => ({
//     progress: {
//         margin: theme.spacing(2)
//     }
// });

function Loader({ style }) {
  //const {classes} = props
  //
  const theme = useTheme();

  return (
    <div
      style={{ position: "relative", margin: theme.spacing(2) }}
      className="animated fadeIn text-center"
    >
      <CircularProgress
        size={40}
        top={0}
        left={0}
        status="loading"
        style={{
          display: "inline-block",
          position: "relative",
        }}
      />
    </div>
  );
}

Loader.propTypes = {};
Loader.defaultProps = {};

export default Loader;
