import React from "react";
import { useNavigate } from "@reach/router";
import withFullBoard from "./HOC/withFullBoard";
import View from "../../layout/view/View";
import Loader from "../../layout/loader/Loader";
import CollectionItem from "./items/CollectionItem";
import Collection from "./items/Collection";

const animatedClassName = "animated fadeInRightShort";

function BoardMenus({ data, baseURL }) {
  const nav = useNavigate();
  if (!data) return <Loader />;
  const navigate = (item) => nav(baseURL + "/menus/" + item.id);
  const { contents = [] } = data;
  return (
    <View verticalCenter className="menus">
      <div className={animatedClassName}>
        <Collection className="go-list full-list">
          {contents.map((menu) => (
            <CollectionItem
              titleProps={{ verticalCenter: false }}
              descriptionProps={{ verticalCenter: false }}
              className="menu-item go-list-item"
              key={menu.id}
              {...menu}
              onClick={() => navigate(menu)}
            />
          ))}
        </Collection>
      </div>
    </View>
  );
}

export default withFullBoard(BoardMenus);
