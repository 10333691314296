import React from "react";
import { ArrowLeftSharp } from "@mui/icons-material";
import "./board.scss";

function Links() {
  return (
    <div className={"board animated fadeIn"}>
      <h1>Réseaux Sociaux</h1>

      <p>
        {" "}
        <ArrowLeftSharp /> instagram
      </p>
      <p>
        {" "}
        <ArrowLeftSharp /> facebook
      </p>
      <p>
        {" "}
        <ArrowLeftSharp /> twitter
      </p>
    </div>
  );
}

Links.propTypes = {};
Links.defaultProps = {};

export default Links;
