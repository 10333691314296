import React, { useEffect } from "react";
//import Header from "../header/Header"
import "./application.scss";
//import { ToasterComponent } from "re-toaster"
//import theme from "../theme/toaster"
//import useToggle from "../../components/hooks/useToggle"
//import withUser from "../../components/auth/HOC/withUser"
//import Footer from "../footer/Footer"

// const setupViewport = () => {
//     let vh = window.innerHeight * 0.01
// // Then we set the value in the --vh custom property to the root of the document
//     document.documentElement.style.setProperty('--vh', `${vh}px`)
// }

function ApplicationLayout({ sidebarRouter, contentRouter, userChecked }) {
  //const [open, toggle] = useToggle(false);

  useEffect(() => {
    //setupViewport()
    const windowResize = () => {
      // We execute the same script as before
      // let vh = window.innerHeight * 0.01
      // document.documentElement.style.setProperty('--vh', `${vh}px`)
    };
    window.addEventListener("resize", windowResize);
    return () => {
      window.removeEventListener("resize", windowResize);
    };
  });
  return (
    <div className="App with-animations">
      {/*<SideBarComponent open={open} toggler={toggle}/>*/}
      {/*<Header toggler={toggle}/>*/}
      {/*<ToasterComponent theme={theme}/>*/}
      {/*{sidebarRouter}*/}
      <div className={"module-content"}>{contentRouter}</div>
      {/*<Footer />*/}
    </div>
  );
}

export default ApplicationLayout;
