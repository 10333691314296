import React from "react";
import View from "../../../layout/view/View";
import { Collapse } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import MenuContentItem from "./MenuContentItem";
import useToggle from "../../../hooks/useToggle";
import MenuName from "./MenuName";

/**
 *
 * @type {MenuData}
 */
const defaultMenu = {
  title: "",
  description: "",
  contents: [],
};

/**
 *
 * @param {MenuContent} menu
 * @param open
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */
const MenuContentTitle = ({ menu, open, onClick }) => {
  return (
    <View
      verticalCenter
      className={`menu-content`}
      onClick={menu.type === "menu" ? onClick : undefined}
    >
      <div className="title">{menu.title}</div>
      {!!menu.description?.trim() && (
        <div className="description">{menu.description?.trim()}</div>
      )}
      {menu.type === "menu" && !!onClick && (
        <div className="toggle-button">
          <ArrowDropDown
            className={`toggle-menu-button ${open ? "open" : ""}`}
            style={{ color: "#323232" }}
          />
        </div>
      )}
    </View>
  );
};
/**
 *
 * @param  {MenuContent} menu
 * @param {boolean} collapsible
 * @returns {JSX.Element}
 * @constructor
 */
const MenuListItem = ({ menu, collapsible = true }) => {
  const [visible, toggle] = useToggle(true);
  const open = !collapsible ? true : visible;
  return (
    <div
      className={`menu-content-wrapper menu-root ${
        menu.type === "title" ? "is-title" : ""
      } ${open ? "menu-open" : ""}`}
      key={menu.id}
    >
      <MenuContentTitle
        open={open}
        menu={menu}
        onClick={collapsible ? toggle : undefined}
      />
      {menu.type === "menu" && (
        <Collapse in={open} timeout="auto">
          <MenuContentItem className={`menu-root`} items={menu.contents} />
        </Collapse>
      )}
    </div>
  );
};

/**
 *
 * @param {MenuData} data
 * @param {boolean} collapsible
 * @returns {JSX.Element}
 * @constructor
 */
const MenuList = ({ data = defaultMenu, collapsible = true }) => {
  return (
    <div className="menu-board">
      <MenuName menu={data} />

      {data.contents.map((menu) => {
        return (
          <MenuListItem collapsible={collapsible} menu={menu} key={menu.id} />
        );
      })}
    </div>
  );
};

export default MenuList;
