//import { /*green900, green700, purple400, green700, blueGrey400,*/blueGrey900 } from "material-ui/styles/colors";
//import getMuiTheme from "material-ui/styles/getMuiTheme";

import { createTheme } from "@mui/material";
import blue from "@mui/material/colors/blue";
// import green from '@mui/material/colors/green';
// import purple from '@mui/material/colors/purple';
// import CssBaseline from '@mui/material/CssBaseline';

//
// const tableRowHeight = 36;
// const globalColor = blueGrey[900];
// const lightColor = "#FFF";

// A theme with custom primary and secondary color.
// It's optional.
const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  //
  // appBar: {
  //   color: lightColor,
  //   textColor: globalColor
  // }
  palette: {
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
    },
    // secondary: {
    //   light: green[300],
    //   main: green[500],
    //   dark: green[700]
    // }
  },
});

//
//
// const selineTheme = getMuiTheme({
//   palette: {
//     primaryColor: globalColor
//   },
//   appBar: {
//     color: lightColor,
//     textColor: blueGrey900
//   },
//   drawer: {
//     color: globalColor
//   },
//   tableHeader: {
//     backgroundColor: "#FAFAFA"
//   },
//   tableRow: {
//     height: tableRowHeight
//   },
//   tableRowColumn: {
//     height: tableRowHeight
//   },
//   tableHeaderColumn: {
//     height: tableRowHeight
//   }
// });

export default theme;
