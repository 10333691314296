import { useState } from 'react'

function useToggle(defaultState = false) {
  const [state, setState] = useState({ visible: defaultState })
  const toggle = (visible) => setState({
    visible: typeof visible === 'boolean' ? visible : !state.visible
  })
  return [state.visible, toggle]
}

export default useToggle