import React from "react";
import { Contents } from "./MenuContentItem";
// import View from '../../../layout/view/View'
// import FoodPrice from '../FoodPrice'
//
// /**
//  * @param {MenuContentSubItem} menu
//  * @returns {JSX.Element}
//  * @constructor
//  */
// const Content = ({ menu }) => {
//   const hasPrice = !!menu.price;
//   const hasMultiplePrices = hasPrice && !!menu.price_2;
//   return (
//     <React.Fragment key={menu.id}>
//       <View
//         verticalCenter
//         className={`menu-content ${hasPrice ? "has-price" : ""} ${
//           hasMultiplePrices ? "has-multiple-price" : ""
//         } ${!hasMultiplePrices ? "has-single-price" : ""}`}
//       >
//         <div className="title-wrapper">
//           <div className="title">{menu.title}</div>
//           {!!menu.description?.trim() && (
//             <div className="description">{menu.description?.trim()}</div>
//           )}
//         </div>
//         {hasPrice && !hasMultiplePrices && <FoodPrice price={menu.price} />}
//         {hasMultiplePrices && (
//           <div className="multiple-prices">
//             {!!menu.price && <FoodPrice price={menu.price} />}
//             {!!menu.price_2 && <FoodPrice price={menu.price_2} />}
//             {!!menu.price_3 && <FoodPrice price={menu.price_3} />}
//           </div>
//         )}
//       </View>
//     </React.Fragment>
//   );
// };

/**
 *
 * @param {MenuContentSubItem[]} items
 * @returns {JSX.Element}
 * @constructor
 */
const MenuContentSubItem = ({ items = [] }) => {
  return (
    <div className="menu-contents">
      {items.map((menu) => {
        return <Contents key={menu.id} menu={menu} />;
      })}
    </div>
  );
};

export default MenuContentSubItem;
