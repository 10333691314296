import React from 'react'
function Collection({children, className}) {
    return (<div className={className}>
        {children}
    </div>)
}

Collection.propTypes = {}
Collection.defaultProps = {}

export default Collection
