import React from "react";
import ApplicationLayout from "../layout/application/ApplicationLayout";
import SinglePageBoard, {
  SinglePageBoardWithoutRouter,
} from "../components/board/SinglePageBoard";
import { Router } from "@reach/router";
import BoardMenus from "../components/board/BoardMenus";
import MenuContents from "../components/board/MenuContents";
import Links from "../components/board/Links";
import SidebarShort from "../layout/sidebar/SidebarShort";

/** Pages **/

//basename={process.env.PUBLIC_URL}
//

const ContentRouter = () => {
  return (
    <React.Fragment>
      <Router basepath={"/:userName/:boardId"}>
        <MenuContents path="/menus/:menuId" />
        <BoardMenus path="/menus" />
        <Links path="/links" />
        <SinglePageBoard path="/" />
      </Router>
      {window.location.hostname === "ctja.sunny.fr" && (
        <Router basepath={"/"}>
          <SinglePageBoardWithoutRouter
            // userName={"cjta"}
            // boardId={"dax-la-feria"}
            userName={"ctja"}
            boardId={"dax-la-feria"}
            path="/"
          />
        </Router>
      )}
    </React.Fragment>
  );
};
const SidebarRouter = () => {
  return (
    <Router
      className="sidebar-short"
      primary={false}
      basepath={"/:userName/:boardId/*"}
    >
      <SidebarShort path={"/*"} />
    </Router>
  );
};

const AppRouter = () => {
  return (
    <ApplicationLayout
      contentRouter={<ContentRouter />}
      sidebarRouter={<SidebarRouter />}
    />
  );
};

export default AppRouter;
