import React from "react";
import withFullBoard, {
  withFullBoardImplicitParams,
} from "./HOC/withFullBoard";
import Loader from "../../layout/loader/Loader"; // import View from '../../layout/view/View'//
//import { useNavigate } from "@reach/router";
import "./single-page-board.scss";
import "./custom.scss";
import MenuList from "./menu/MenuList";
import InstagramPosts from "./instagram/InstagramPosts";

/**
 *
 * @param {Block} block
 * @returns {JSX.Element}
 * @constructor
 */
const CustomBlock = ({ block }) => {
  return <div dangerouslySetInnerHTML={{ __html: block.content }} />;
};

/**
 * @param {MenuData} data
 * @returns {JSX.Element}
 * @constructor
 */

function SinglePageBoard({ data, boardOptions, boardId, ...props }) {
  //const history = useNavigate();

  //console.log(boardOptions)
  //const collapsible = boardId !== "ctja-dax-la-feria";
  const collapsible = boardOptions.menu_toggle; //boardId !== "ctja-dax-la-feria";

  if (!data) return <Loader />;
  //const navigate = () => history(baseURL + "/menus");

  return (
    <div className={"single-page-board animated fadeIn"}>
      {data.blocks.map((block) => {
        if (!block.block_id) {
          return (
            <div key={block.id} className="menu-wrapper">
              <MenuList collapsible={collapsible} data={data} />
            </div>
          );
        }
        if (block.content === "instagram") {
          return (
            <InstagramPosts
              userName={props.userName}
              key={block.id}
              block={block}
            />
          );
        }
        return <CustomBlock key={block.id} block={block} />;
      })}
    </div>
  );
}

export const SinglePageBoardWithoutRouter =
  withFullBoardImplicitParams(SinglePageBoard);

export default withFullBoard(SinglePageBoard);
