import React from 'react'
import Loader from '../layout/loader/Loader'
import CalloutComponent from '../layout/error/CalloutComponent'
function withUseFetchLoader(Wrapped) {
  return (originalProps) => {
    const { loading, error } = originalProps
    if (error) return <CalloutComponent error={error}/>
    if (loading) return <Loader/>
    return <Wrapped {...originalProps}/>
  }
}

withUseFetchLoader.propTypes = {}
withUseFetchLoader.defaultProps = {}

export default withUseFetchLoader
