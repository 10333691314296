import React from "react";
import { IconButton } from "@mui/material";
import { Home, InsertLink, Menu as MenuIcon } from "@mui/icons-material";
import { useNavigate, useParams } from "@reach/router";
import { getURLParams } from "../../components/board/HOC/withUrlParams";

const SidebarShort = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { homeURL } = getURLParams(params || {});
  const menuURL = homeURL + "/menus";
  const linksURL = homeURL + "/links";
  const navigateToHome = () => navigate(homeURL);
  const navigateToMainMenu = () => navigate(menuURL);
  const navigateToSocialLinks = () => navigate(linksURL);
  // const navigateBack = () => history.goBack()
  // const isRoot = history.pathname === homeURL
  //const isMenu = history.location.pathname === menuURL

  return (
    <React.Fragment>
      <IconButton
        className="sidebar-short-menu-item"
        onClick={() => navigateToHome()}
      >
        <Home />
      </IconButton>
      <IconButton
        className="animated fadeIn sidebar-short-menu-item"
        onClick={() => navigateToMainMenu()}
      >
        <MenuIcon />
      </IconButton>
      {/*{!isRoot && (*/}
      {/*    <IconButton*/}
      {/*        className="animated fadeIn sidebar-short-menu-item"*/}
      {/*        onClick={() => navigateBack()}>*/}
      {/*        <ArrowLeft/>*/}
      {/*    </IconButton>*/}
      {/*)}*/}
      <IconButton
        className="animated fadeIn sidebar-short-menu-item"
        onClick={() => navigateToSocialLinks()}
      >
        <InsertLink />
      </IconButton>
    </React.Fragment>
  );
};

export default SidebarShort;
