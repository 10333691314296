import React from "react"
import "./error.scss"
import PropTypes from "prop-types"
import SnackbarContent from "../../layout/Snackbar/SnackbarContent"

const defaultTitle = "Error"
const defaultMessage = "Something is not working"


export function isNotFound(error) {
  return error !== null
    && typeof error === "object"
    && typeof error.response === "object"
    && error.response.status === 404
}

export function isNotAuthorized(error) {
  return error !== null
    && typeof error === "object"
    && typeof error.response === "object"
    && error.response.status === 401
}


class CalloutComponent extends React.Component {
  title = (error, defaultTitle) => {
    const notFound = isNotFound(error)
    const notAuthorized = isNotAuthorized(error)

    if (notFound) return "Not found"
    if (notAuthorized) return "Not Authorized"

    return defaultTitle
  }

  message = (error) => {
    if (!error || !error.response || !error.response.data) {
      return ""
    }
    if (typeof error.response.data === "string") return error.response.data

    if (typeof error.response.data === "object" && error.response.data.message) {
      return error.response.data.message
    }
    return defaultMessage
  }

  render() {
    const { error, source, title } = this.props
    if (source) console.log("%c" + source, "color: #5e3dc2; font-weight: bold;")
    if (error) console.log(error)

    const _title = this.title(error, title || defaultTitle)


    return <SnackbarContent
      message={(
        <span>
            <strong>{_title}</strong> {this.message(error)}
          </span>
      )}
      //close
      color="danger"
      icon="info_outline"
    />

    // return (<div className="callout callout-warning" style={style || {}}>
    //     <h4>{_title}</h4>
    //     <p>{typeof error === "string" ? error : defaultMessage}</p>
    //     {extText ? <p>{extText}</p> : null}
    //     {children}
    // </div>);
  }
}

CalloutComponent.propTypes = {
  error: PropTypes.any,
  title: PropTypes.string,
  style: PropTypes.object,
  source: PropTypes.string
}

export default CalloutComponent