let API = "/";

API =
  window.location.host === "localhost:3012"
    ? "http://localhost:4004/"
    : "https://go.sunny.fr/";
//API = "http://localhost:4004/";

const config = {
  boards: {
    board: API + "api/board/{userName}/{boardId}",
    options: API + "api/board/{userName}/{boardId}/options",
  },
  instagram: {
    posts: API + "api/insta/{userName}",
  },
};

export default config;
