import React from "react";

const styles = {
  common: {
    //display:'flex',
  },
  center: {
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center'
  },
  row: {
    // flexDirection: 'row'
  },
  verticalCenter: {
    // flexDirection: 'row',
    // justifyContent: 'center',
    // alignItems: 'center'
  },
};

function View({
  center,
  row,
  verticalCenter,
  style,
  children,
  className,
  onClick,
}) {
  let combined = { ...styles.common };
  if (row) Object.assign(combined, styles.row);
  if (center) Object.assign(combined, styles.center);
  if (verticalCenter) Object.assign(combined, styles.verticalCenter);
  Object.assign(combined, style || {});
  return (
    <div onClick={onClick} className={className} style={combined}>
      {children}
    </div>
  );
}

View.propTypes = {};
View.defaultProps = {};

export default View;
