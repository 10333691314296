import React, { useCallback, useEffect } from "react";
import useBoard from "../hooks/userBoard";

function withBoard(Wrapped) {
  return (props) => {
    const [error, loading, response, fetchBoard] = useBoard({
      boardId: props.boardId,
      userName: props.userName,
    });

    const arc = {
      error,
      loading,
      data: response ? response.data : null,
      fetch: fetchBoard,
    };
    const fetchCallback = useCallback(() => fetchBoard(), [fetchBoard]);

    useEffect(() => {
      fetchCallback();
    }, [fetchCallback]);

    return <Wrapped {...arc} {...props} />;
  };
}

withBoard.propTypes = {};
withBoard.defaultProps = {};

export default withBoard;
