import React from "react";

/**
 *
 * @param {MenuData} menu
 * @returns {JSX.Element}
 * @constructor
 */
const MenuName = ({ menu }) => {
  return (
    <React.Fragment>
      <div className="menu-name">
        <h1>{menu.title}</h1>
        {!!menu.description?.trim() && <p>{menu.description.trim()}</p>}
      </div>
      {/*<Button style={{ paddingLeft: 0 }} onClick={navigate}>*/}
      {/*  Découvrir le menu <ArrowRight />*/}
      {/*</Button>   */}
    </React.Fragment>
  );
};

export default MenuName;
