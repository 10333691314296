import React from "react";
import View from "../../../layout/view/View";
import MenuContentSubItem from "./MenuContentSubItem";
import FoodPrice from "../FoodPrice";

/**
 * @param {MenuContentItem|MenuContentSubItem} menu
 * @returns {JSX.Element}
 * @constructor
 */
export const Contents = ({ menu }) => {
  const hasPrice = !!menu.price;
  const hasMultiplePrices = hasPrice && !!menu.price_2;
  return (
    <div className="menu-content-wrapper" key={menu.id}>
      <View
        verticalCenter
        className={`menu-content ${hasPrice ? "has-price" : ""} ${
          hasMultiplePrices ? "has-multiple-prices" : ""
        } ${!hasMultiplePrices ? "has-single-price" : ""}`}
      >
        <div className="title-wrapper">
          <div className="title">{menu.title}</div>
          {!!menu.description?.trim() && (
            <div className="description">{menu.description?.trim()}</div>
          )}
        </div>

        {hasPrice && !hasMultiplePrices && <FoodPrice price={menu.price} />}
        {hasMultiplePrices && (
          <div className="prices-wrapper">
            {!!menu.price && (
              <FoodPrice
                price={menu.price}
                price_desc={menu.price_desc}
                price_icon={menu.price_icon}
              />
            )}
            {!!menu.price_2 && (
              <FoodPrice
                price={menu.price_2}
                price_desc={menu.price_desc_2}
                price_icon={menu.price_icon_2}
              />
            )}
            {!!menu.price_3 && (
              <FoodPrice
                price={menu.price_3}
                price_desc={menu.price_desc_3}
                price_icon={menu.price_icon_3}
              />
            )}
          </div>
        )}
      </View>
      {!!menu.contents && menu.contents.length > 0 && (
        <MenuContentSubItem items={menu.contents} />
      )}
    </div>
  );
};
/**
 *
 * @param {MenuContentItem[]} items
 * @param {string} className
 * @returns {JSX.Element}
 * @constructor
 */
const MenuContentItem = ({ items = [], className = "" }) => {
  return (
    <div className={`menu-contents ${className}`}>
      {items.map((menu) => {
        return <Contents menu={menu} key={menu.id} />;
      })}
    </div>
  );
};

export default MenuContentItem;
