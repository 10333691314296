import React from "react";

const currency = "€";
const FoodPrice = ({ price, price_desc, price_icon }) => {
  if (!price) return null;
  const priceParsed = parseFloat(price);
  const [units, decimals] = priceParsed.toFixed(2).split(".");
  return (
    <div className="price-tag">
      <div className="food-price">
        {!!price_desc?.trim() && (
          <span className="price-desc">{price_desc}</span>
        )}
        {!!price_icon?.trim() && (
          <span className="price_icon">{price_icon}</span>
        )}
        <span className="units">{price}</span>
        <span className="currency">{currency}</span>
        {/*<span className="decimals">*/}
        {/*  /!*{currency}*!/*/}
        {/*  /!*{decimals}*!/*/}
        {/*  {price}*/}
        {/*  */}
        {/*</span>*/}
      </div>
    </div>
  );
};

export default FoodPrice;
