import { compose } from "redux";
import withUrlParams from "./withUrlParams";
import withBoard from "./withBoard";
import withUseFetchLoader from "../../../HOC/withUseFetchLoader";
import withBoardOptions from "./withBoardOptions";

function withFullBoard(Wrapped) {
  return compose(
    withUrlParams,
    withBoardOptions,
    withBoard,
    withUseFetchLoader
  )(Wrapped);
}

export function withFullBoardImplicitParams(Wrapped) {
  return compose(withBoardOptions, withBoard, withUseFetchLoader)(Wrapped);
}

export default withFullBoard;
