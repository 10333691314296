import React from "react";
import View from "../../../layout/view/View";
import FoodPrice from "../FoodPrice";
import { ArrowRight } from "@mui/icons-material";

const styles = {
  container: {
    width: "100%",
    boxSizing: "border-box",
  },
};

function CollectionItem({
  title,
  description,
  price,
  className,
  children,
  onClick,
  titleProps = {},
  descriptionProps = {},
}) {
  const clickable = !!onClick;
  const hasPrice = !!price;
  return (
    <div
      className={className}
      style={onClick ? { cursor: "pointer" } : {}}
      onClick={onClick}
    >
      <div
        style={
          hasPrice
            ? { ...styles.container, paddingRight: "20%", position: "relative" }
            : styles.container
        }
      >
        {hasPrice && <FoodPrice price={price}>{description}</FoodPrice>}
        <div className="wrapper-title">
          {!!title && (
            <View verticalCenter {...titleProps} className="title">
              {title} {clickable && !description && <ArrowRight />}
            </View>
          )}
          {!!description && (
            <View verticalCenter {...descriptionProps} className="description">
              {description} {clickable && <ArrowRight />}
            </View>
          )}
        </div>
      </div>
      {children}
    </div>
  );
}

export default CollectionItem;
