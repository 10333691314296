import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import withInstagramPosts from '../HOC/withInstagramPosts'
import './instagram.scss'
// const getLastPosts = (data) => {
//   console.log(data.graphql.user.edge_owner_to_timeline_media.edges);
//   return data.graphql.user.edge_owner_to_timeline_media.edges.map(
//     ({ node }) => {
//       console.log(node);
//
//       const description =
//         node?.edge_media_to_caption?.edges[0]?.node?.text || "";
//
//       const image = node?.display_url || "";
//       const thumbnail = node?.thumbnail_resources[0]?.src || "";
//       console.log(description, image);
//       const id = node.id;
//       return {
//         id,
//         image: thumbnail,
//         description,
//       };
//     }
//   );
// };

const InstagramPostsCarousel = ({ data }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    className: "center",
    centerMode: true,
  };

  return (
    <div className="last-instagram-posts">
      <div className="last-instagram-posts-title">
        <h2 className="text-center">SUR INSTAGRAM</h2>
        <p className="text-center">Les derniers posts</p>
      </div>
      <Slider {...settings}>
        {data.map(({ id, thumbnail, caption }) => {
          return (
            <div key={id}>
              <div className="instagram-post-wrapper">
                <a href="https://www.instagram.com/ctja.dax" target="_blank">
                  <img src={thumbnail} alt={caption} />
                </a>
                <p>{caption}</p>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const InstagramPosts = ({ loading, error, data, fetch: fetchPosts }) => {
  if (error || loading || !data) return <div />;
  return (
    <div>
      <InstagramPostsCarousel data={data} />
    </div>
  );
};

export default withInstagramPosts(InstagramPosts);
