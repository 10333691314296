import {useFetch} from 'use-http'
import {interpolate} from 'react-arc'
import config from '../../../config/config'
//import {useRef} from 'react'

function useBoard({userName, boardId}) {
    const url = interpolate(config.boards.board, {userName, boardId })

    const {get, response, loading, error} = useFetch(url, {
        // cacheLife: 0,
        // cachePolicy: 'no-cache',
        headers: {
            "Content-Type": "application/json",
        },
    })
    return [error, loading, response, get]
}

export default useBoard