import React from "react";
import { useParams } from "@reach/router";
import { interpolate } from "react-arc";

export const getURLParams = (params) => {
  let baseURL = interpolate("/{userName}/{boardId}", params);
  let homeURL = baseURL;
  if (params.menuId) {
    baseURL = interpolate("/{userName}/{boardId}/menus/{menuId}", params);
  }
  return {
    homeURL,
    baseURL,
  };
};

const withUrlParams = (Wrapped) => (props) => {
  const params = useParams();
  return <Wrapped {...props} {...params} {...getURLParams(params)} />;
};

export default withUrlParams;
