import React, { useCallback, useEffect } from "react";
import userBoardOptions from "../hooks/userBoardOptions";
import CalloutComponent from "../../../layout/error/CalloutComponent";
import Loader from "../../../layout/loader/Loader";

function withBoardOptions(Wrapped) {
  return (props) => {
    const [error, loading, response, fetchBoardOptions] = userBoardOptions({
      boardId: props.boardId,
      userName: props.userName,
    });

    // const arc = {
    //   error,
    //   loading,
    //   data: response ? response.data : null,
    //   fetch: fetchBoardOptions,
    // };
    const fetchCallback = useCallback(
      () => fetchBoardOptions(),
      [fetchBoardOptions]
    );

    useEffect(() => {
      fetchCallback();
    }, [fetchCallback]);

    if (error) return <CalloutComponent error={error} />;
    if (loading || !response.data) return <Loader />;

    return <Wrapped {...props} boardOptions={response.data} />;
  };
}

export default withBoardOptions;
