import React, { useCallback, useEffect } from "react";
import userInstagram from "../hooks/userInstagram";

function withInstagramPosts(Wrapped) {
  return ({ userName, ...props }) => {
    const [error, loading, response, fetchInstaPosts] = userInstagram({
      userName,
    });

    const arc = {
      error,
      loading,
      data: response ? response.data : null,
      fetch: fetchInstaPosts,
    };
    const fetchCallback = useCallback(
      () => fetchInstaPosts(),
      [fetchInstaPosts]
    );

    useEffect(() => {
      fetchCallback();
    }, [fetchCallback]);

    return <Wrapped {...arc} {...props} />;
  };
}

export default withInstagramPosts;
